import { Permissions } from "../types/Permissions";
import { ConfigByRoleInfo } from "../types/User";

export interface RouteData {
  permission: Permissions | Permissions[];
  name: string;
  path?: string;
  root: string;
  label?: string;
  views?: RouteData[];
}

export interface ViewProcess {
  [process_id: string]: {
    // procesos para filtrar
    tasks: number[]; //tasks a filtrar
    action: number; //action a llamar
    forms: {
      //informacion de renderizacion del modal
      [form_id: string]: string[];
    };
    label: string; //Nombre del boton cuando se elijan de este proceso
  };
}

export interface ViewFormat {
  permission: Permissions | Permissions[];
  name: string;
  path?: string;
  root: string;
  domain?: number[];
  label?: string;
  hide?: boolean;
  checkAllowMetadata?: boolean;
  process?: ViewProcess;
  config_by_role?: { [role: string]: ConfigByRoleInfo };
}

export interface ViewProps extends ViewFormat {
  views?: ViewFormat[];
}

export interface Route {
  permission: Permissions | Permissions[];
  name: string;
  icon?: any;
  label?: string;
  path?: string;
  domain?: number[];
  views?: ViewProps[];
  hide?: boolean;
  key?: string;
  config_by_role?: { [role: string]: ConfigByRoleInfo };
}

const routes: Route[] = [
  {
    name: "home",
    icon: "pe-7s-diamond",
    permission: Permissions.NO_VALIDATE,
    views: [
      {
        path: "/accounts",
        name: "accounts",
        root: "/home",
        permission: Permissions.ACCOUNT,
      },
      {
        root: "/home",
        name: "calendar",
        path: "/calendar",
        permission: Permissions.CALENDAR,
      },
      {
        path: "/inbox",
        name: "myTasks",
        root: "",
        permission: Permissions.TASKS,
      },
      {
        path:
          typeof window !== "undefined"
            ? window.location.origin + "/web-designer/#/"
            : "",
        name: "Web designer",
        root: "",
        permission: Permissions.NO_VALIDATE,
        domain: [129, 281],
      },
      {
        path:
          typeof window !== "undefined"
            ? window.location.origin + "/omnichannel/#/"
            : "",
        name: "Omnichannel",
        root: "",
        permission: Permissions.NO_VALIDATE,
        domain: [129, 281],
      },

      {
        path: "/driver-tasks?process_id=4",
        name: "bulkRoutes",
        root: "/admin",
        domain: [272],
        permission: Permissions.NO_VALIDATE,
      },
    ],
  },
  {
    name: "sales",
    icon: "pe-7s-piggy",
    permission: Permissions.SALES_GOAL,
    views: [
      {
        path: "/sales-goal",
        name: "sales-goal",
        root: "/sales",
        permission: Permissions.SALES_GOAL,
      },
      {
        path: "/purchase-by-month",
        name: "purchase-by-month",
        permission: Permissions.NO_VALIDATE,
        root: "/sales",
      },
    ],
  },
  {
    name: "analytics",
    icon: "pe-7s-helm",
    permission: Permissions.REPORT,
    views: [
      {
        path: "/customer-cohorts",
        name: "customerCohorts",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/productgroup",
        name: "productGroup",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/marketing",
        name: "Marketing UTM",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "customerGrowth",
        root: "/analytics/customer-growth",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "customerEvents",
        root: "/analytics/customer-events",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "claims",
        root: "/analytics/claims",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "products-sold",
        root: "/analytics/products-sold",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "inventory",
        root: "/analytics/inventory",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/re-order",
        name: "purchase-by-re-order",
        root: "/analytics/purchase",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/products-sold/most-sold",
        name: "most-sold",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/search-terms",
        name: "search-terms",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
    ],
  },
  {
    name: "process",
    icon: "pe-7s-hourglass",
    permission: Permissions.PROCESS_MODEL,
    views: [
      {
        path: "/",
        name: "projectManager",
        root: "/admin/project-manager",
        permission: Permissions.NO_VALIDATE,
      },
      {
        path: "/list",
        name: "projectList",
        root: "/admin/project-manager",
        permission: Permissions.PROCESS_MODEL_READ,
      },
      {
        path: "/launch",
        name: "launchProcess",
        root: "/admin/project-manager",
        permission: Permissions.NO_VALIDATE,
      },
    ],
  },
  {
    name: "forms",
    icon: "pe-7s-safe",
    permission: Permissions.FORM_BUILDER,
    views: [
      {
        path: "/form-list",
        name: "form-list",
        root: "/forms",
        permission: Permissions.FORM_BUILDER,
      },
    ],
  },
  {
    name: "communications",
    icon: "pe-7s-headphones",
    permission: Permissions.EMAIL_TEMPLATE,
    views: [
      {
        path: "/email-blast",
        name: "emailBlast",
        root: "/communications",
        permission: Permissions.EMAIL_TEMPLATE,
      },
      {
        path: "/template-generator",
        name: "template-generator",
        root: "/communications",
        permission: Permissions.EMAIL_TEMPLATE,
      },
    ],
  },
  {
    name: "data-providers",
    icon: "pe-7s-next-2",
    permission: Permissions.DATA_PROVIDER,
    views: [
      {
        path: "/data-provider",
        name: "data-providers",
        root: "/providers",
        permission: Permissions.DATA_PROVIDER_READ,
      },
    ],
  },
  // {
  //   name: "example-sub-route",
  //   icon: "pe-7s-cloud-download",
  //   permission: Permissions.NO_VALIDATE,
  //   views: [{
  //     name: "example",
  //     root: "/example",
  //     permission: Permissions.NO_VALIDATE,
  //     path: "/example",
  //     views: [
  //       {
  //         path: "/example",
  //         name: "example",
  //         root: "/example/example",
  //         permission: Permissions.NO_VALIDATE
  //       }
  //     ]
  //   }]
  // },
  {
    name: "data",
    icon: "pe-7s-cloud-download",
    permission: Permissions.DATA_MANAGER,
    views: [
      // {
      //   path: "/products",
      //   name: "products",
      //   root: "/admin",
      //   permission: Permissions.NO_VALIDATE
      // }
    ],
  },
  {
    name: "proceedings",
    icon: "pe-7s-news-paper",
    permission: Permissions.NO_VALIDATE,
    views: [],
  },
  {
    name: "bulk_updates",
    icon: "pe-7s-upload",
    permission: Permissions.NO_VALIDATE,
    views: [],
  },
  {
    name: "reports",
    icon: "pe-7s-display1",
    permission: Permissions.REPORT,
    views: [
      {
        name: "crm-reports",
        root: "/analytics",
        permission: [Permissions.REPORT_READ, Permissions.REPORT_EXECUTE],
        path: "/crm-reports",
      },
      {
        name: "report-generator",
        root: "/analytics",
        permission: Permissions.REPORT_CREATE,
        path: "/report-generator",
      },
      // {name: "report-generator2", root: "/analytics", permission: Permissions.REPORT_CREATE, path: "/report-generator"},
    ],
  },
  {
    name: "config",
    icon: "pe-7s-settings",
    permission: [Permissions.USER, Permissions.APP_CONFIG],
    views: [
      {
        path: "/config",
        name: "app",
        root: "/admin",
        permission: Permissions.APP_CONFIG,
      },
      {
        path: "/config/manager",
        name: "App-manager",
        root: "/admin",
        permission: Permissions.APP_CONFIG,
      },
      {
        path: "/admin_users",
        name: "admin-users",
        root: "/admin/users",
        permission: Permissions.USER_READ,
      },
      {
        path: "/permissions-assignment",
        name: "permissions-assignment",
        root: "/permissions",
        permission: Permissions.PERMISSION_ASSIGNMENT,
      },
      {
        path: "/groups_manager",
        name: "groups_manager",
        root: "/permissions",
        permission: [Permissions.GROUP],
      },
      {
        path: "/",
        name: "crm_execution",
        root: "/admin/crm_execution",
        permission: Permissions.APP_CONFIG,
      },
    ],
  },
];

export default routes;

function combineRoutes(externalRoutes: Route[]): Route[] {
  const configRoutes = routes.reduce((routes: Route[], route) => {
    const newRoute = { ...route };

    if (externalRoutes.length) {
      const nRoute = externalRoutes.find(
        (cRoute) => cRoute.name === newRoute.name,
      );
      if (nRoute) {
        newRoute.name = nRoute.name;
        if (nRoute.permission) {
          newRoute.permission = nRoute.permission;
        }

        newRoute.hide = nRoute.hide ?? false;

        newRoute.views = newRoute.views?.reduce((views: ViewProps[], view) => {
          if (nRoute.views) {
            const nView = nRoute.views.find(
              (cView) => cView.name === view.name,
            );

            if (nView) {
              views.push(nView);
            } else {
              views.push(view);
            }
          } else {
            views.push(view);
          }

          return views;
        }, []);
      }

      if (nRoute?.views) {
        nRoute.views.forEach((view) => {
          if (!newRoute.views?.some((v) => v.name === view.name)) {
            newRoute.views?.push(view);
          }
        });
      }

      routes.push(newRoute);
    }

    return routes;
  }, []);
  // setUserRoutes(config.sbx_crm.routes);

  const newRoutes = externalRoutes.filter(
    (nRoute) =>
      !configRoutes.some((cRoute) => cRoute.name === nRoute.name) &&
      nRoute.views &&
      nRoute.views.length > 0 &&
      nRoute.views.every((view) => view.name && view.root && view.path),
  );

  return [...configRoutes, ...newRoutes].filter((route) => !route.hide);
}

export const mappingRoutes = (otherRoutes: Route[]) => {
  const newRoutes = [
    {
      label: "Menu",
      items: combineRoutes(otherRoutes),
    },
  ];

  return newRoutes;
};
